const mixpanel = require('mixpanel-browser');

export const reset = () => {
  try {
    mixpanel.reset();
  } catch (ex) {
    console.warn(`Unable to reset mixpanel: ${ex}`);
  }
};

export const init = () => {
  const token = 'a12b40d382e14bbf23164ec7dc1eaa6b';
  try {
    mixpanel.init(token);
  } catch (ex) {
    console.warn(`Unable to initialize mixpanel with api key: ${ex}`);
  }
};

export const track = (eventName: string, properties?: object) => {
  try {
    mixpanel.track(eventName, properties);
  } catch (ex) {
    console.warn(`Error calling this.mixpanel.track: ${ex}`);
  }
};