import { createSelector } from 'reselect';
import { RootState } from '../state/Root';
import LanguageState from '../state/Language';

/**
 * Direct selector to the Pairing state domain
 */
const selectLocaleState = (state: RootState) => state.language;

const selectCurrentLocale = createSelector(
  selectLocaleState,
  (language: LanguageState) => {
    return language.get('locale');
  }
);

export {
  selectCurrentLocale
};
