import { Store, createStore } from 'redux';
import createReducer from './reducers';
import combineMiddleware from './middleware';
import { RootState, Root } from './state/Root';

export default function configureStore(): Store<RootState> {
  return createStore(
    createReducer(),
    new Root(),
    combineMiddleware()
  ) as Store<RootState>;
}
