
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { WelcomeForm } from '../../components/MainLayout/WelcomeForm';
import { RootState } from '../../state/Root';
import intl from 'react-intl-universal';
import {
  accountSettingsDataSelector,
  accountSettingsErrorSelector,
  validateEmailDataSelector,
  validateEmailErrorSelector,
  validateNameErrorSelector,
  saveUserDataSelector,
  saveUserErrorSelector,
  saveUserLoadingSelector,
  accessTokenSelector,
} from '../../selectors/welcomeSelector';
import { actions } from '../../actions/welcomeActions';
import { saveUserRequestObject } from '../../constants/welcomeConstants';

const getErrorMessage = (errorPayload: string) => {
  switch (errorPayload) {
    case 'USER_LIMIT_REACHED':
      return intl.get('errorAccountLimitReached');
    case 'DUPLICATE_EMAIL':
      return intl.get('errorEmailInUse');
    case 'DUPLICATE_NAME':
      return intl.get('errorNameInUse');
    case 'INVALID_EMAIL_DOMAIN':
      return intl.get('errorEmailDomainInvalid');
    case 'INVALID_PARAMETER':
      return intl.get('errorEmailRequired');
    case 'SAVE_USER_ERROR':
      return intl.get('errorSaveUser');
    case 'INVALID_TOKEN':
      return intl.get('errorExpiredLink');
    case 'GET_ACCOUNT_SETTINGS_ERROR':
      return intl.get('errorGetAccountSettings');
    default:
      return errorPayload;
  }
};

const mapStateToProps = (state: RootState) => {
  validateEmailDataSelector(state);
  return {
    isSSO: accountSettingsDataSelector(state).isSSO,
    domainFilters: accountSettingsDataSelector(state).domainFilters,
    ssoDomains: accountSettingsDataSelector(state).ssoDomains,
    accountSettingsError: accountSettingsErrorSelector(state),
    validateEmailError: getErrorMessage(validateEmailErrorSelector(state)),
    validateNameError: getErrorMessage(validateNameErrorSelector(state)),
    saveUserData: saveUserDataSelector(state),
    saveUserError: getErrorMessage(saveUserErrorSelector(state)),
    saveUserLoading: saveUserLoadingSelector(state),
    access_token: accessTokenSelector(state),
  }
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  validateEmail: (email: string) => {
    dispatch(actions.validateEmail(email));
  },
  validateName: (name: string) => {
    dispatch(actions.validateName(name));
  },
  saveUser: (userObject: saveUserRequestObject) => dispatch(actions.saveUser(userObject)),
});

const WelcomeFormContainer = connect(mapStateToProps, mapDispatchToProps)(WelcomeForm);
export { WelcomeFormContainer };
