import React from 'react';
import styles from './WelcomeLayout.module.scss';
import Icon from '../Common/Icon';
import intl from 'react-intl-universal';
import { accountSettingsResponseObject } from '../../constants/welcomeConstants';
import { WelcomeFormContainer } from '../../containers/MainLayout/WelcomeFormContainer';

interface Props {
  accessToken: string,
  accountSettingsData?: accountSettingsResponseObject;
  accountSettingsError: string;
  accountSettingsLoading: boolean;
  fetchAccountSettings: () => void,
}

export class WelcomeLayout extends React.Component<Props> {

  componentDidMount() {
    this.props.fetchAccountSettings();
  }

  render() {
    return (
      <div className={styles.container} >
        <div className={styles.content}>
          <Icon classes={['icon-lsLogo', styles.icon]} />
          <div className={styles.formContainer}>
            {
              !this.props.accessToken ?
                <div className={styles.header}>{intl.get('invalid')}</div> :
                this.props.accountSettingsLoading ?
                  <div className={styles.header}>{intl.get('loading')}</div> :
                  this.props.accountSettingsError && this.props.accountSettingsError === 'INVALID_TOKEN' ?
                    <div className={styles.header}>{intl.get('errorExpiredLink')}</div> :
                  <WelcomeFormContainer />
            }
          </div>
        </div>
      </div>
    );
  }
}