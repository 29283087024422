export enum Actions {
  CHANGE_LOCALE = 'CHANGE_LOCALE'
}

/**
 * There are many language codes, but this enum is what is supported
 */
export enum SupportedLanguages {
    cs = 'cs',
    de = 'de',
    en = 'en',
    es = 'es',
    fi = 'fi',
    fr = 'fr',
    it = 'it',
    ko = 'ko',
    ja = 'ja',
    nl = 'nl',
    no = 'no',
    pl = 'pl',
    pt = 'pt',
    sv = 'sv',
    zh = 'zh'
}

export const DEFAULT_LOCALE: string = 'en';
