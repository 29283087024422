export enum Actions {
  SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN',
  GET_ACCOUNT_SETTINGS = 'GET_ACCOUNT_SETTINGS',
  GET_ACCOUNT_SETTINGS_START = 'GET_ACCOUNT_SETTINGS',
  GET_ACCOUNT_SETTINGS_SUCCESS = 'GET_ACCOUNT_SETTINGS_SUCCESS',
  GET_ACCOUNT_SETTINGS_ERROR = 'GET_ACCOUNT_SETTINGS_ERROR',
  VALIDATE_EMAIL = 'VALIDATE_EMAIL',
  VALIDATE_EMAIL_START = 'VALIDATE_EMAIL_START',
  VALIDATE_EMAIL_SUCCESS = 'VALIDATE_EMAIL_SUCCESS',
  VALIDATE_EMAIL_ERROR = 'VALIDATE_EMAIL_ERROR',
  VALIDATE_NAME = 'VALIDATE_NAME',
  VALIDATE_NAME_START = 'VALIDATE_NAME_START',
  VALIDATE_NAME_SUCCESS = 'VALIDATE_NAME_SUCCESS',
  VALIDATE_NAME_ERROR = 'VALIDATE_NAME_ERROR',
  SAVE_USER = 'SAVE_USER',
  SAVE_USER_START = 'SAVE_USER_START',
  SAVE_USER_SUCCESS = 'VALIDATE_NAMESAVE_USER_SUCCESS_SUCCESS',
  SAVE_USER_ERROR = 'SAVE_USER_ERROR',
}

export type payloadType = accountSettingsResponseObject | validateResponseObject | successSaveResponseObject | string;

export interface saveUserRequestObject {
  name: string;
  email: string;
  password?: string;
  accept: boolean;
  access_token: string;
}

export interface accountSettingsResponseObject {
  success: boolean;
  isSSO: boolean;
  ssoDomains: string[];
  domainFilters?: string[];
  error?: errorResponseObject;
}

export interface validateResponseObject {
  success: boolean;
  error?: errorResponseObject;
}

export interface successSaveResponseObject {
  success: boolean;
  error?: errorResponseObject;
}

export interface errorResponseObject {
  code: 'DUPLICATE_EMAIL' | 'DUPLICATE_NAME' | 'INVALID_EMAIL_DOMAIN' | 'SAVE_USER_ERROR' | 'GET_ACCOUNT_SETTINGS_ERROR' | 'UNKNOWN_ERROR' | string;
}

// REACT_APP_API_DOMAIN=https://userApi.lifesizecloud.com or REACT_APP_API_DOMAIN=https://userApi.lifesizecloudbeta.com
export const apiDomain = process.env.REACT_APP_API_DOMAIN;
const basePath = '/invites'
// GET requests
export const getAccountSettingsPath = `${basePath}/accountSettings?AT=`;
export const validateEmailPath = `${basePath}/validateEmail?email=`;
export const validateNamePath = `${basePath}/validateName?name=`;
export const accesTokenQuery = '&AT=';
// POST requests
export const saveUserPath = `${basePath}/saveUser`;
