import { MiddlewareAPI, Dispatch } from 'redux';
import { PayloadAction } from '@lifesize/typescript-react-redux-utils';
import { Actions} from '../constants/welcomeConstants';

export const SaveSuccessMiddleware =
  (store: MiddlewareAPI) =>
    (next: Dispatch) => {
      return (action: PayloadAction<string, string>) => {
        if (Actions.SAVE_USER_SUCCESS !== action.type) {
          return next(action);
        }
        window.location.replace(process.env.REACT_APP_DOWNLOAD_PAGE || '');
      };
    };
