import { Record } from 'immutable';
import { accountSettingsResponseObject, validateResponseObject, successSaveResponseObject } from '../constants/welcomeConstants';

export interface WelcomeState {
  accountSettingsLoading: boolean;
  accountSettingsData: accountSettingsResponseObject;
  accountSettingsError: string;
  validateEmailLoading: boolean;
  validateEmailData: validateResponseObject;
  validateEmailError: string;
  validateNameLoading: boolean;
  validateNameData: validateResponseObject;
  validateNameError: string;
  saveUserLoading: boolean;
  saveUserData: successSaveResponseObject;
  saveUserError: string;
  access_token: string;
}

const initialState: WelcomeState = {
  accountSettingsLoading: false,
  accountSettingsData: {success: false, isSSO: false, domainFilters: [], ssoDomains: []},
  accountSettingsError: '',
  validateEmailLoading: false,
  validateEmailData: { success: false},
  validateEmailError: '',
  validateNameLoading: false,
  validateNameData: { success: false },
  validateNameError: '',
  saveUserLoading: false,
  saveUserData: {
    success: false,
  },
  saveUserError: '',
  access_token: '',
};

export default class Welcome extends Record(initialState) {
}
