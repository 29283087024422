import { Actions, apiDomain, getAccountSettingsPath, validateEmailPath, validateNamePath, accesTokenQuery, saveUserPath } from '../constants/welcomeConstants';
import axios, { AxiosRequestConfig } from 'axios';

export const getStartDispatchAction = (ActionType: string) => {
  switch (ActionType) {
    case Actions.GET_ACCOUNT_SETTINGS:
      return Actions.GET_ACCOUNT_SETTINGS_START;
    case Actions.VALIDATE_EMAIL:
      return Actions.VALIDATE_EMAIL_START;
    case Actions.VALIDATE_NAME:
      return Actions.VALIDATE_NAME_START;
    case Actions.SAVE_USER:
      return Actions.SAVE_USER_START;
    default:
      return '';
  }
}

export const getSuccessDispatchAction = (ActionType: string) => {
  switch (ActionType) {
    case Actions.GET_ACCOUNT_SETTINGS:
      return Actions.GET_ACCOUNT_SETTINGS_SUCCESS;
    case Actions.VALIDATE_EMAIL:
      return Actions.VALIDATE_EMAIL_SUCCESS;
    case Actions.VALIDATE_NAME:
      return Actions.VALIDATE_NAME_SUCCESS;
    case Actions.SAVE_USER:
      return Actions.SAVE_USER_SUCCESS;
    default:
      return '';
  }
}

export const getErrorDispatchAction = (ActionType: string) => {
  switch (ActionType) {
    case Actions.GET_ACCOUNT_SETTINGS:
      return Actions.GET_ACCOUNT_SETTINGS_ERROR;
    case Actions.VALIDATE_EMAIL:
      return Actions.VALIDATE_EMAIL_ERROR;
    case Actions.VALIDATE_NAME:
      return Actions.VALIDATE_NAME_ERROR;
    case Actions.SAVE_USER:
      return Actions.SAVE_USER_ERROR;
    default:
      return '';
  }
}

export const getRequestType = (ActionType: string) => {
  switch (ActionType) {
    case Actions.SAVE_USER:
      return 'post';
    default:
      return 'get';
  }
}

export const buildURL = (ActionType: string, access_token: string, queryParam: string) => {
  switch (ActionType) {
    case Actions.GET_ACCOUNT_SETTINGS:
      return `${apiDomain}${getAccountSettingsPath}${access_token}`;
    case Actions.VALIDATE_EMAIL:
      return `${apiDomain}${validateEmailPath}${encodeURIComponent(queryParam)}${accesTokenQuery}${encodeURIComponent(access_token)}`;
    case Actions.VALIDATE_NAME:
      return `${apiDomain}${validateNamePath}${encodeURIComponent(queryParam)}${accesTokenQuery}${encodeURIComponent(access_token)}`;
    case Actions.SAVE_USER:
      return `${apiDomain}${saveUserPath}`;
    default:
      return '';
  }
}

export const simpleAPICall = async (url: string, config: AxiosRequestConfig) => {
    return await axios(url, config)
}