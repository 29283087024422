import { Record } from 'immutable';
import { getFirstBrowserLanguage, getLocaleCodeFromUrl } from '../utils/language-utils';

export interface LanguageState {
  locale: string;
}

const initialState: LanguageState = {
  locale: getLocaleCodeFromUrl() || getFirstBrowserLanguage()
};

export default class Language extends Record(initialState) {
}
