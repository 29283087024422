export const locales = {
    'cs': require('./cs.json'),
    'de': require('./de.json'),
    'en': require('./en.json'),
    'es': require('./es.json'),
    'fi': require('./fi.json'),
    'fr': require('./fr.json'),
    'it': require('./it.json'),
    'ja': require('./ja.json'),
    'ko': require('./ko.json'),
    'nl': require('./nl.json'),
    'no': require('./no.json'),
    'pl': require('./pl.json'),
    'pt': require('./pt.json'),
    'sv': require('./sv.json'),
    'zh': require('./zh.json'),
};
