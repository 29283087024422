import { Actions, payloadType, accountSettingsResponseObject, validateResponseObject, successSaveResponseObject } from '../constants/welcomeConstants';
import { PayloadAction } from '@lifesize/typescript-react-redux-utils';
import Welcome from '../state/Welcome';

export const welcomeProviderReducer =
  (state = new Welcome(), action: PayloadAction<Actions, payloadType>) => {
    switch (action.type) {
      case Actions.SET_ACCESS_TOKEN:
        return state.set('access_token', action.payload as string);
      case Actions.GET_ACCOUNT_SETTINGS_START:
        return state.set('accountSettingsLoading', true);
      case Actions.GET_ACCOUNT_SETTINGS_SUCCESS:
        return state.set('accountSettingsLoading', false).set('accountSettingsData', action.payload as accountSettingsResponseObject);
      case Actions.GET_ACCOUNT_SETTINGS_ERROR:
        return state.set('accountSettingsLoading', false).set('accountSettingsError', action.payload as string);

      case Actions.VALIDATE_EMAIL_START:
        return state.set('validateEmailLoading', true).set('validateEmailError', '');
      case Actions.VALIDATE_EMAIL_SUCCESS:
        return state.set('validateEmailLoading', false).set('validateEmailError', '').set('validateEmailData', action.payload as validateResponseObject);
      case Actions.VALIDATE_EMAIL_ERROR:
        return state.set('validateEmailLoading', false).set('validateEmailError', action.payload as string);

      case Actions.VALIDATE_NAME_START:
        return state.set('validateNameLoading', true);
      case Actions.VALIDATE_NAME_SUCCESS:
        return state.set('validateNameLoading', false).set('validateNameError', '').set('validateNameData', action.payload as validateResponseObject);
      case Actions.VALIDATE_NAME_ERROR:
        return state.set('validateNameLoading', false).set('validateNameError', action.payload as string);

      case Actions.SAVE_USER_START:
        return state.set('saveUserLoading', true);
      case Actions.SAVE_USER_SUCCESS:
        return state.set('saveUserLoading', false).set('saveUserData', action.payload as successSaveResponseObject);
      case Actions.SAVE_USER_ERROR:
        return state.set('saveUserLoading', false).set('saveUserError', action.payload as string);

      default:
        return state;
    }
  };
