import { MiddlewareAPI, Dispatch } from 'redux';
import { PayloadAction } from '@lifesize/typescript-react-redux-utils';
import { Actions, validateResponseObject } from '../constants/welcomeConstants';
import { simpleAPICall, getStartDispatchAction, getErrorDispatchAction, getSuccessDispatchAction, buildURL, getRequestType } from './UserAPI';
import { AxiosRequestConfig } from 'axios';
import { accessTokenSelector } from '../selectors/welcomeSelector';


const allowedActionTypes = [
  Actions.GET_ACCOUNT_SETTINGS.toString(),
  Actions.SAVE_USER.toString(),
  Actions.VALIDATE_EMAIL.toString(),
  Actions.VALIDATE_NAME.toString()
];

const getAxiosConfig = (action: PayloadAction<string, string>) => {
  const axiosConfig: AxiosRequestConfig = {
    method: getRequestType(action.type),
    responseType: 'json',
  };

  if (action.type === Actions.SAVE_USER) {
    axiosConfig.data = action.payload;
  }
  return axiosConfig;
}

export const GetAccountSettingsMiddleware =
  (store: MiddlewareAPI) =>
    (next: Dispatch) => {
      return (action: PayloadAction<string, string>) => {
        if (!allowedActionTypes.includes(action.type)) {
          return next(action);
        }
        next({ type: getStartDispatchAction(action.type) });

        const url = buildURL(action.type, accessTokenSelector(store.getState()), action.payload);

        return simpleAPICall(url, getAxiosConfig(action)).then(res => {
          const responseBody = (res) ? res.data as validateResponseObject : undefined;
          if (responseBody && responseBody.error) {
            return next({ type: getErrorDispatchAction(action.type), payload: responseBody.error.code });
          }

          return next({ type: getSuccessDispatchAction(action.type), payload: responseBody });
        })
          .catch((err: any) => {
            return next({ type: getErrorDispatchAction(action.type), payload: (err.message) ? err.message : err });
          });
      };
    };
