import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as styles from './LanguageDropdown.module.scss';
import languages from '../../data/supportedLanguages.json';
import { RootState } from '../../state/Root';
import actions from '../../actions/languageActions';
import { selectCurrentLocale } from '../../selectors/languageSelector';

interface IState {
  isExpanded: boolean;
}

interface IProps {
  currentLocale: string;
  changeLocale: (code: string) => void;
}

class LanguageDropdown extends React.Component<IProps, IState> {
  readonly state = { isExpanded: false };

  public handleToggle = (event: any) => {
    this.setState({
      isExpanded: !this.state.isExpanded,
    });
    event.preventDefault();
  };

  handleSelectLanguage = (code: string) => {
    this.setState({
      isExpanded: false,
    });
    this.props.changeLocale(code);
  };

  createListItems = () => {
    const listItems: Array<{}> = [];
    Object.keys(languages).forEach((key) => (
      listItems.push(
        <li key={languages[key].code} onClick={() => this.handleSelectLanguage(languages[key].code)}>
          <span>
            {languages[key].name}
          </span>
        </li>)
    ));
    return listItems
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.dropdownInput} onClick={this.handleToggle}>
          {languages[this.props.currentLocale].name}
          <span className={styles.arrowDown} />
        </div>
        {
          (this.state.isExpanded) ?
            <div className={styles.dropdown}>
              <ul>
                {this.createListItems()}
              </ul>
            </div> :
            null
        }
      </div>
    );
  }
}

function mapStateToProps(state: RootState, ownProps: {}) {
  const currentLocale = selectCurrentLocale(state);
  return {
    currentLocale,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  changeLocale: (locale: string) => {
    dispatch(actions.changeLocale(locale));
  }
});

const LanguageDropdownContainer = connect(mapStateToProps, mapDispatchToProps)(LanguageDropdown);
export { LanguageDropdownContainer };

