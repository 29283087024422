import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/es/array';
import './styles/core.scss';
import '@lifesize/react-components/dist/css/button.min.css';
import '@lifesize/react-components/dist/css/dropdown.min.css';
import '@lifesize/react-components/dist/css/transition.min.css';
import '@lifesize/react-components/dist/css/checkbox.min.css';
import * as React from 'react';
import { Store } from 'redux';
import { RootState } from './state/Root';
import { Provider } from 'react-redux';
import configureStore from './store';
import * as ReactDOM from 'react-dom';
import { AppContainer } from './App';
import registerServiceWorker from './registerServiceWorker';
declare global {
  interface Window { store: {}; }
}

const store: Store<RootState> = configureStore();
// tslint:disable-next-line
(window as any).store = store;

ReactDOM.render(
  <Provider store={store}>
    <AppContainer />
  </Provider>,
  document.getElementById('root') as HTMLElement
);
registerServiceWorker();
