
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { WelcomeLayout } from '../../components/MainLayout/WelcomeLayout';
import { RootState } from '../../state/Root';
import {
  accessTokenSelector,
  accountSettingsDataSelector,
  accountSettingsErrorSelector,
  accountSettingsLoadingSelector,
} from '../../selectors/welcomeSelector';
import { actions } from '../../actions/welcomeActions';

const mapStateToProps = (state: RootState) => {
  return {
    accessToken: accessTokenSelector(state),
    accountSettingsData: accountSettingsDataSelector(state),
    accountSettingsError: accountSettingsErrorSelector(state),
    accountSettingsLoading: accountSettingsLoadingSelector(state),
  }
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchAccountSettings: () => {
    dispatch(actions.fetchAccountSettings());
  },
});

const MainLayoutContainer = connect(mapStateToProps, mapDispatchToProps)(WelcomeLayout);
export { MainLayoutContainer };
