import { Record } from 'immutable';
import LanguageState from '../state/Language';
import WelcomeState from '../state/Welcome';

export interface RootState {
  language: LanguageState;
  welcome: WelcomeState
}

const initialState: RootState = {
  language: new LanguageState(),
  welcome: new WelcomeState()
};

export class Root extends Record(initialState) {}

