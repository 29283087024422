import { createAction } from '@lifesize/typescript-react-redux-utils';
import { Actions, saveUserRequestObject } from '../constants/welcomeConstants';

export const actions = {
  setAccessToken: (access_token: string) =>
    createAction(Actions.SET_ACCESS_TOKEN, access_token),
  fetchAccountSettings: () =>
    createAction(Actions.GET_ACCOUNT_SETTINGS),
  validateEmail: (emailAddress: string) =>
    createAction(Actions.VALIDATE_EMAIL, emailAddress),
  validateName: (name: string) =>
    createAction(Actions.VALIDATE_NAME, name),
  saveUser: (userObject: saveUserRequestObject) =>
    createAction(Actions.SAVE_USER, userObject),
};
