import React from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { actions } from './actions/welcomeActions';
import { MainLayoutContainer } from './containers/MainLayout/MainLayoutContainer';
import { locales } from "./locales/locale";
import { selectCurrentLocale } from './selectors/languageSelector';
import { RootState } from './state/Root';
import { init as initMixpanel, track } from './utils/analytics';

interface Props {
    currentLocale: string;
    setAccessToken: (access_token: string) => void;
}

initMixpanel();

track('Welcome - Landed on Page.');

const localeDataUrl = process.env.REACT_APP_LOCALE_DATA;

const commonLocaleDataUrls = {
    'cs': `${localeDataUrl}en.js`,
    'de': `${localeDataUrl}de.js`,
    'en': `${localeDataUrl}en.js`,
    'es': `${localeDataUrl}es.js`,
    'fi': `${localeDataUrl}en.js`,
    'fr': `${localeDataUrl}fr.js`,
    'it': `${localeDataUrl}it.js`,
    'ja': `${localeDataUrl}ja.js`,
    'ko': `${localeDataUrl}ko.js`,
    'nl': `${localeDataUrl}nl.js`,
    'no': `${localeDataUrl}en.js`,
    'pl': `${localeDataUrl}pl.js`,
    'pt': `${localeDataUrl}pt.js`,
    'sv': `${localeDataUrl}sv.js`,
    'zh': `${localeDataUrl}zh.js`,
};

class App extends React.Component<Props> {
    state = { initDone: false };

    async componentDidMount() {
        this.props.setAccessToken(window.location.pathname.split('/')[window.location.pathname.split('/').length - 1].trim());
        await intl.init({
            currentLocale: this.props.currentLocale,
            commonLocaleDataUrls: commonLocaleDataUrls,
            locales,
        });
        this.setState({ initDone: true });
    }

    async componentDidUpdate(prevProps: Props) {
        if (prevProps.currentLocale && (this.props.currentLocale !== prevProps.currentLocale)) {
            this.setState({ initDone: false });
            await intl.init({
                currentLocale: this.props.currentLocale || 'en',
                locales,
            });
            this.setState({ initDone: true });
        }
    }

    render() {
        const { initDone } = this.state;
        return (
            <React.Fragment>
                {initDone && <MainLayoutContainer />}
            </React.Fragment>
        );
    };
}

function mapStateToProps(state: RootState) {
    return {
        currentLocale: selectCurrentLocale(state)
    };
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
    setAccessToken: (access_token: string) => {
        dispatch(actions.setAccessToken(access_token));
    }
});

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);
export { AppContainer };
