import React from 'react';
import { Formik, Form, Field, ErrorMessage, FormikValues, FormikErrors, FormikTouched } from 'formik';
import styles from './WelcomeLayout.module.scss';
import intl from 'react-intl-universal';
import { Button, Checkbox } from 'semantic-ui-react';
import * as Yup from 'yup';
import { LanguageDropdownContainer } from '../Common/LanguageDropdown';
import { successSaveResponseObject, saveUserRequestObject } from '../../constants/welcomeConstants';

interface Props {
  isSSO: boolean;
  domainFilters?: string[];
  ssoDomains?: string[];
  accountSettingsError: string,
  validateEmailError?: string,
  validateNameError?: string,
  saveUserData?: successSaveResponseObject,
  saveUserError?: string,
  saveUserLoading: boolean,
  access_token: string,
  validateEmail: (email: string) => void,
  validateName: (name: string) => void,
  saveUser: (userObject: saveUserRequestObject) => void,
}

export const WelcomeForm = ({ isSSO, domainFilters, validateEmail, validateName, validateEmailError, validateNameError, saveUser, access_token, saveUserError, ssoDomains }: Props) => {

  const AcceptCheckboxComponent = (
    values: FormikValues,
    setFieldValue: Function,
    errors: FormikErrors<FormikValues>,
    touched: FormikTouched<FormikValues>,
    setFieldTouched: Function
  ) => {
    return (
      <>
        <div className={styles.accept}>
          <Checkbox
            onChange={
              () => {
                setFieldValue('accept', !values.accept, true);
                setFieldTouched('accept');
              }
            }
            checked={!!values.accept}
          />
          {intl.getHTML('accept', { locale: intl.getInitOptions().currentLocale })}
        </div>
        {
          errors.accept && touched.accept ?
            <div className={styles.error}>
              {errors.accept}
            </div> :
            null
        }
      </>
    )
  };

  let cache = {};
  const memoize = (fn: Function) => {
    return (...args: any[]) => {
      let n = args[0];  // just taking one argument here
      if (n in cache) {
        return cache[n];
      } else {
        let result = fn(n);
        cache[n] = result;
        return result;
      }
    }
  };

  const getDomainName = (email: string) => {
    const domainNameArray = email.split('@');
    let domainName;
    if (domainNameArray && domainNameArray.length > 1) {
      domainName = domainNameArray[1];
    }
    return domainName;
  };

  const emailValidation = (emailAddress: string) => {
    const email = emailAddress.trim().toLocaleLowerCase();
    const domainName = getDomainName(email);
    if (isSSO && ssoDomains && ssoDomains.length) {
      let isInSSODomains = false;
      if (domainName) {
        isInSSODomains = ssoDomains.includes(domainName);
      }
      if (isInSSODomains) {
        return intl.get('errorSSODomainMatch');
      }
    }
    if (domainFilters && domainFilters.length) {
      let isInDomainFilters = false;
      if (domainName) {
        const normalizedDomainFilters = domainFilters.map(domain => domain.toLowerCase());
        isInDomainFilters = normalizedDomainFilters.includes(domainName);
      }
      if (!isInDomainFilters) {
        return intl.get('errorEmailDomainInvalid');
      }
    }
    const memoizedValidateEmail = memoize(validateEmail);
    memoizedValidateEmail(email);
  };

  const nameValidation = (nameString: string) => {
    const name = nameString.trim();
    // Check if the email is set.
    if (!name) {
      return intl.get('errorNameRequired');
    }
    const memoizedValidateName = memoize(validateName);
    memoizedValidateName(name);
  };

  const SignupSchema = Yup.object().shape({
    name: Yup.string().trim()
      .required(intl.get('errorNameRequired'))
      .min(2, intl.get('errorNameShort'))
      .max(32, intl.get('errorNameLong')),
    password: Yup.string().trim()
      .required(intl.get('errorPasswordRequired'))
      .min(8, intl.get('errorPasswordInfo'))
      .max(32, intl.get('errorPasswordInfo')),
    confirmpassword: Yup.string().trim()
      .required(intl.get('errorConfirmedPasswordRequired'))
      .test('same-password', intl.get('errorPasswordMatch'), function (value) {
        // ie11 does not like Yup.ref
        return this.parent.password === value;
      }),
    email: Yup.string().trim()
      .required(intl.get('errorEmailRequired'))
      .email(intl.get('errorEmailInvalid')),
    accept: Yup.boolean().test('must-accept', intl.get('errorTerms'), function (value) {
      // ie11 didn't like the Yup.boolean().oneOf([true]...
      return value === true;
    }),
  });

  /**
   * Aside from internal Yo schema validation, this will return true
   * if there was server side email validation error.
   */
  const hasExternalEmailValidationError = memoize((validateEmailError: string | undefined) => {
    return validateEmailError && validateEmailError.length > 0;
  });

  return (
    <>
      <div className={styles.header}>{intl.get('create')}</div>
      <Formik
        initialValues={{ name: '', email: '', password: '', confirmpassword: '', accept: false }}
        validationSchema={SignupSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={async (values, { setSubmitting, validateForm, resetForm }) => {
          delete (values.confirmpassword);
          delete (values.accept);
          await saveUser({ ...values, access_token: access_token } as saveUserRequestObject);
          resetForm({ ...values, password: '', confirmpassword: '' });
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, isValid, dirty, isValidating, values, setFieldValue, errors, touched, setFieldTouched }) => (
          <Form className={styles.form}>
            <div className={styles.formGroup}>
              <label className={styles.label}>{intl.get('language')}</label>
              <LanguageDropdownContainer/>
            </div>

            <div className={styles.formGroup}>
              <label className={styles.label} htmlFor="email">{intl.get('email')}</label>
              <Field type="email" name="email" className={styles.input} autoComplete="email"
                     validate={emailValidation}/>
              {
                !hasExternalEmailValidationError(validateEmailError) &&
                <ErrorMessage name="email" component="div" className={styles.error}/>
              }
              {
                hasExternalEmailValidationError(validateEmailError) &&
                <div className={styles.error}>
                  {validateEmailError}
                </div>
              }
            </div>

            <div className={styles.formGroup}>
              <label className={styles.label} htmlFor="name">{intl.get('name')}</label>
              <Field type="name" name="name" className={styles.input} autoComplete="name"
                     validate={nameValidation}/>
              <ErrorMessage name="name" component="div" className={styles.error}/>
              {
                validateNameError ?
                  <div className={styles.error}>
                    {validateNameError}
                  </div> :
                  null
              }
            </div>

            <div className={styles.formGroup}>
              <label className={styles.label} htmlFor="password">{intl.get('password')}</label>
              <Field type="password" name="password" className={styles.input} autoComplete="new-password"/>
              <ErrorMessage name="password" component="div" className={styles.error}/>
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label} htmlFor="confirmpassword">{intl.get('confirmPassword')}</label>
              <Field type="password" name="confirmpassword" className={styles.input} autoComplete="new-password"/>
              <ErrorMessage name="confirmpassword" component="div" className={styles.error}/>
            </div>

            <Field
              name="accept"
              className={styles.input}
              component={
                () => {
                  return (AcceptCheckboxComponent(values, setFieldValue, errors, touched, setFieldTouched))
                }
              }
            />

            <Button
              className={styles.submitButton}
              type="submit"
              primary={true}
              size={'medium'}
              loading={isSubmitting}
              disabled={isValidating || isSubmitting || !isValid || !!validateNameError || !!validateEmailError}
            >
              {intl.get('createAccount')}
            </Button>
            {
              saveUserError && !dirty ?
                <div className={styles.error}>
                  {saveUserError}
                </div> :
                null
            }
          </Form>
        )}
      </Formik>
    </>
  );

};
