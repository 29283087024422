import { createSelector } from 'reselect';
import { RootState } from '../state/Root';
import WelcomeState from '../state/Welcome';

const selectWelcomeState = (state: RootState) => state.welcome;

const accessTokenSelector = createSelector(
  selectWelcomeState,
  (welcome: WelcomeState) => {
    return welcome.get('access_token');
  }
);
const accountSettingsLoadingSelector = createSelector(
  selectWelcomeState,
  (welcome: WelcomeState) => {
    return welcome.get('accountSettingsLoading');
  }
);
const accountSettingsDataSelector = createSelector(
  selectWelcomeState,
  (welcome: WelcomeState) => {
    return welcome.get('accountSettingsData');
  }
);
const accountSettingsErrorSelector = createSelector(
  selectWelcomeState,
  (welcome: WelcomeState) => {
    return welcome.get('accountSettingsError');
  }
);
const validateEmailLoadingSelector = createSelector(
  selectWelcomeState,
  (welcome: WelcomeState) => {
    return welcome.get('validateEmailLoading');
  }
);
const validateEmailDataSelector = createSelector(
  selectWelcomeState,
  (welcome: WelcomeState) => {
    return welcome.get('validateEmailData');
  }
);
const validateEmailErrorSelector = createSelector(
  selectWelcomeState,
  (welcome: WelcomeState) => {
    return welcome.get('validateEmailError');
  }
);
const validateNameLoadingSelector = createSelector(
  selectWelcomeState,
  (welcome: WelcomeState) => {
    return welcome.get('validateNameLoading');
  }
);
const validateNameDataSelector = createSelector(
  selectWelcomeState,
  (welcome: WelcomeState) => {
    return welcome.get('validateNameData');
  }
);
const validateNameErrorSelector = createSelector(
  selectWelcomeState,
  (welcome: WelcomeState) => {
    return welcome.get('validateNameError');
  }
);
const saveUserLoadingSelector = createSelector(
  selectWelcomeState,
  (welcome: WelcomeState) => {
    return welcome.get('saveUserLoading');
  }
);
const saveUserDataSelector = createSelector(
  selectWelcomeState,
  (welcome: WelcomeState) => {
    return welcome.get('saveUserData');
  }
);
const saveUserErrorSelector = createSelector(
  selectWelcomeState,
  (welcome: WelcomeState) => {
    return welcome.get('saveUserError');
  }
);

export {
  accessTokenSelector,
  accountSettingsDataSelector,
  accountSettingsErrorSelector,
  accountSettingsLoadingSelector,
  validateEmailDataSelector,
  validateEmailErrorSelector,
  validateEmailLoadingSelector,
  validateNameDataSelector,
  validateNameErrorSelector,
  validateNameLoadingSelector,
  saveUserDataSelector,
  saveUserErrorSelector,
  saveUserLoadingSelector
};
