import { combineReducers } from 'redux-immutable';
import languageProviderReducer from './reducers/languageReducer';
import { welcomeProviderReducer } from './reducers/welcomeReducer';

export default function createReducer() {
  return combineReducers({
    language: languageProviderReducer,
    welcome: welcomeProviderReducer,
  });
}
