/**
 * Combine all middleware in this file and export the combined middleware.
 */
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { applyMiddleware } from 'redux';
import { GetAccountSettingsMiddleware } from './middleware/GetAccountSettingsMiddleware';
import { SaveSuccessMiddleware} from './middleware/SaveSuccessMiddleware';

const env = process.env.NODE_ENV;

export default function combineMiddleware() {
  
  
  const filteredLogger = createLogger({
    predicate: () => (env !== 'test')
  });
  const middlewares = [thunk, GetAccountSettingsMiddleware, SaveSuccessMiddleware, filteredLogger]
  return applyMiddleware(...middlewares);
}
