import { Actions } from '../constants/languageConstants';
import { PayloadAction } from '@lifesize/typescript-react-redux-utils';
import Language from '../state/Language';

const languageProviderReducer =
  (state = new Language(), action: PayloadAction<Actions, string>) => {
    switch (action.type) {
      case Actions.CHANGE_LOCALE:
        return state.set('locale', action.payload);
      default:
        return state;
    }
  };

export default languageProviderReducer;
